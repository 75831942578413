<template>
  <v-container fluid fill-height class="pa-0 ma-0 narekomu-back" >
    <div class="login-back people"></div>
    <DialogStatus 
      class="login-card"
      v-if="sItem.type"
      :item="sItem"
      v-on:closeEmit="reset"
    />
    <v-card v-else-if="!complete"  class="elevation-1   login-card  body-2" >
 
      <v-toolbar
          color="blue-grey darken-4"
          dark
          flat
          height="80"
      > 
        <v-btn icon  link to="/"><v-icon>{{icons.arrowLeft}}</v-icon></v-btn>
        <v-row justify="center">
          <v-text class="text-h6 mr-6">ユーザー登録</v-text>
        </v-row>
      </v-toolbar>
       
      <v-card-text class="mt-3 pb-1">
     
        以下の項目を入力してください。<br>
        入力したメールアドレスに本登録用のリンクが送信されます。
        <v-form ref="createForm">
          <v-text-field
            outlined
            dense
            name="login"
            label="メールアドレス"
            type="text"
            v-model="email"
            :counter="255"
            :error-messages="emailErrors"
            required
            class="my-1"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          ></v-text-field>
          <v-text-field
            name="password"
            label="パスワード"
            id="password1"
            type="password"
            outlined
            dense
            v-model="password1"
            :counter="32"
            :error-messages="password1Errors"
            required
            @input="$v.password1.$touch()"
            @blur="$v.password1.$touch()"
            class="my-1"
          ></v-text-field>
          <v-text-field
            name="password"
            label="パスワードの確認"
            id="password2"
            type="password"
            outlined
            dense
            v-model="password2"
            :counter="32"
            :error-messages="password2Errors"
            required
            class="my-1"
            @input="$v.password2.$touch()"
            @blur="$v.password2.$touch()"
          ></v-text-field>
        </v-form>
        <div class="blue-grey lighten-5  overflow-y-auto my-2 "   style="max-height: 250px">
          <DocTerms/>
        </div>
        <v-checkbox
          v-model="checkbox"
          label="利用規約に同意する"
          class="pa-0 ma-0"
        ></v-checkbox>

      </v-card-text>
      <div class="login-btn px-4 pb-4 text-center">
        <v-chip v-if="sItem.errorText" class="ma-1 white--text " color="pink lighten-2">{{this.sItem.errorText}}</v-chip>
        <v-btn block    color="blue-grey darken-4 white--text" @click="createAccount" :loading="loading" :disabled="checkInputs">送信</v-btn>
      </div>
      <div class="pa-4"><p style="border-top:1px solid gray  "></p></div>
    </v-card>
    <v-card v-else-if="complete" class="elevation-1   login-card  body-2" >
      <v-toolbar
          color="blue-grey darken-4"
          dark
          flat
          height="80"
      >    
        <v-btn icon  link to="/"><v-icon>{{arrowLeft}}</v-icon></v-btn>
        <v-row justify="center">
          <v-text class="text-h6 mr-6">ユーザー登録</v-text>
        </v-row>
      </v-toolbar>
        <v-card-text  class="mt-3 pb-1">
        会員登録確認メールを送信しました。<br>
        まだ会員登録は完了していません。<br>
        入力したメールアドレスに確認メールを送信しましたので、1時間以内に確認メールに記載されているリンクから本登録を行ってください。<br>
        *迷惑メールに入っていることがあるため、確認してください。

        
        
      </v-card-text>
      <div  class="pa-4">
        <p style="border-top:1px solid gray  "></p>
         <v-row justify="center" class="mr-2;">
          <v-btn 
            link 
            text
            to="/home"
            dense
            id="no-background-hover"
            class="mb-4 mt-2 primary--text text-center"
            @click="reset"
            >トップページへ</v-btn>
        </v-row>
      </div>
    </v-card>
   </v-container>
</template>

<script>
import axios from "axios"
import { validationMixin } from 'vuelidate'
import { required, maxLength , minLength } from 'vuelidate/lib/validators'
import { mdiArrowLeft } from '@mdi/js'


const DocTerms = () => import('@/components/DocTerms.vue');
const DialogStatus = () => import('@/components/DialogStatus.vue');

// import store from "../store"
export default {
  components: { DocTerms, DialogStatus },
  name: 'CreateAccount',
  mixins: [validationMixin],
  validations: {
    email: { required, maxLength: maxLength(255) },
    password1: { required, maxLength: maxLength(32) , minLength: minLength(8) },
    password2: { required, maxLength: maxLength(32) , minLength: minLength(8) },
  },
  data: () => ({
      icons:{
        "arrowLeft":mdiArrowLeft
      },
    checkbox:false,
    loading: false,
    showPassword:false,
    isNoMatch:false,
    disable:false,
    complete:false,
    sItem:{
      dialog:false,
      type:"",
      loadText:`送信中...`,
      reload:true,
      toTop:true,
      persist:true,
    },
    email: "",
    password1:"",
    password2:"",
    initialDataConfiguration: null
  }),

  ready: function (){
    //grabbing this here so that we can reset the data when we close the window.
    this.initialDataConfiguration= this.$data;
  },

  computed:{

    checkInputs(){
      if(this.email &&
         this.email.length <= 255 &&
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email) &&
          this.password1 &&
          this.password1.length <= 32 &&
          this.password1.length >= 8  &&
          this.password2 &&
          this.password2.length <= 32 &&
          this.password2.length >= 8  &&
          (this.password1 == this.password2) &&
          this.checkbox 
          ){
            return false;
          } 

      else return true;
    },

    emailErrors(){
      let errors = [];

      if (!this.$v.email.$dirty) return errors
      else if(!this.email) errors.push("メールアドレスは必須項目です。");
      else if( this.email.length > 255) errors.push( "メールアドレスは255文字以内で入力してください。");
      else if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email)){
        errors.push("メールアドレスの形式が正しくありません。");
      }
      return errors;
    },

    password1Errors(){
      let errors = [];
      if (!this.$v.password1.$dirty) return errors;
      else if(!this.password1) errors.push("必須項目です。");
      else if( this.password1.length > 32) errors.push( "パスワードは32文字以内で入力してください。");
      else if( this.password1.length < 8) errors.push( "パスワードは8文字以上で入力してください。");

      return errors;
    },

    password2Errors(){
      let errors = [];
      if (!this.$v.password2.$dirty) return errors;
      else if(!this.password2) errors.push("必須項目です。");
      else if( this.password2.length > 32) errors.push( "パスワードは32文字以内で入力してください。");
      else if( this.password2.length < 8) errors.push( "パスワードは8文字以内で入力してください。");
      else if( this.password2 != this.password1 ) errors.push( "パスワードが一致していません。");
      return errors;
    },


  },



  methods: {
    //ユーザーの仮登録
    createAccount () {  
      if (this.$refs.createForm.validate()) {
        this.sItem.type = "load";
        this.sItem.errorText = ""
        axios({
            method: "POST",
            url:`${process.env.VUE_APP_URL}account`,
            headers: {"X-Api-Key": process.env.VUE_APP_KEY, "Content-Type":"application/json" },
            data:{ UserId:this.email, Password:this.password1},
        }).then(() => {
            // console.log(res.data)
            this.sItem.type = "";
            this.complete=true;
        }).catch(err => {
          // console.log(err)
          // this.sItem.type = "error";
          this.sItem.type = "";
          if(err.response.data=="InvalidUserId" ) this.sItem.errorText = "このユーザーはすでに存在しています。"
          else this.sItem.errorText = "予期せぬエラーが発生しました。(err.response.data)"
        })
      }
    },
    // data初期化
    reset : () => {
      this.$data= this.initialDataConfiguration;
    },
  },
  
}
</script>

<style  scoped>
    .login-card {
        width:100%;
        max-width:650px;
        margin: auto;
        z-index:2000;
    }

    .narekomu-back{
        /* background: url("../assets/login-narekomuvr.webp"); */
        background-size: cover;
        background-position: center center;
        width: 100%;
    }

    #no-background-hover::before {
      background-color: transparent !important;
    }

    .login-back{
      position:absolute;
       left: 0;
      right: 0;
      margin: auto;
      width:100%;
      max-width:1400px;
      height:100%;
    }

    .people{
      animation: fadein 0.4s ease-out forwards;
      background: url("~@/assets/people.webp") no-repeat center center;
      background-size:auto 100%;
    }

    
      .male{
        position:absolute;
   
        right:0%;
        height:100%;
        max-width:850px;
        min-width:100px;
        bottom:0;
        z-index:1000;
        object-fit:cover;
        object-position:right top;
      }

      .female{
        position:absolute;
        height:100%;
        left:0%;
        bottom:0;
        z-index:900;
        max-width:850px;
        min-width:100px;
        object-fit:cover;
        object-position:left top;
      }


    @media screen and (max-width: 1000px) {
      .male{
         position:absolute;
        height:100%;
        left:0;
        max-width:850px;
        min-width:100px;
        width:50%;
        bottom:0;
        z-index:1000;
        object-fit:cover;
        object-position:50% top;
      }

      .female{
        position:absolute;
        height:100%;
        left:50%;
        bottom:0;
       
        z-index:900;
        max-width:850px;
        min-width:100px;
        width:60%;
        object-fit:cover;
        object-position:50% top;
      } 

    }


</style>